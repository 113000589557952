
:root{
    --color-principal: #311c38;
    --color-fondo-popUp: #311c3880;
    --color-fondo-encima: #a594af;
    --color-suave: #e5e1e6;
    --color-oscuro: #6b357f;
    --tamanyo-letra: 15px;
    --tamanyo-letra-grande: 18px;
    --color-muysuave: #fcf8ff;

    /* Colores aceptados */
    --color-error:#DF1626;
    --color-aceptado:#16df85;
    --color-warning:#DFBE16;
    /* Escala de grises */
    --color-negro:#000;
    --color-gris0:#49494C;
    --color-gris1:#808080;
    --color-gris2:#B8B8B8;
    --color-gris3:#EBEBEB;
    --color-gris4:#F1F1F1;
    --color-gris5:#F9F9F9;
    --color-blanco:#fff;
    /* Shadow */
    --shadow-small:3px 3px 6px rgba(0,0,0,.30);
    --shadow-big:6px 6px 12px rgba(0,0,0,.16);
    /* Border radius */
    --border-radius-mini:4px;
    --border-radius-small:8px;
    --border-radius-big:12px;
    /* Disabled opacity */
    --opacity-desactivado:.4;
}
::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--color-fondo-popUp); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-principal); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-oscuro); 
  }
.avanzadoLateral{overflow: hidden;}
button:disabled{
    opacity: 0.4;
}
.vacio .ng-select-container{
    border: 1px solid red !important;
}

.buttonsinimg{
    background-color: var(--color-principal);
    color: white;
    font-family: 'IBM Plex Sans';
    border-radius: 7px;
    border: solid 1px var(--color-principal);
}

.titulo{ 
    font-size: 22px; 
    font-weight: normal;
    font-family: 'IBM Plex Sans';
    color: var(--color-principal);
}

input[type="image"]{
    width: 2.5%;
}

label{
    color: var(--color-principal);
    font-weight: bold;
    font-family: 'IBM Plex Sans';
}

body{
    background-color: #fffbfb;
    font-family: 'IBM Plex Sans';
}

html{
    width: 100%;
    height: 100%;
}

button{
    cursor: pointer;
}
input[type="submit"], input[type="month"], input[type="radio"]{
    cursor: pointer;
}
span > label{
    cursor: pointer;
}
span{
    cursor: pointer;
}
svg{
    cursor: pointer;
}
select{
    cursor: pointer;
}

.full_width{width: 100%;}
.ng-select.full_width{display: inline-block;width: 100%;}
.ng-select.full_width{width: 100%;}
.ng-select.full_width.ng-select-multiple{width: 100%;max-width: 100%;}
.ng-select.full_width.ng-select-multiple .ng-value-container{max-width: 100%;}
.input.full_width{width: calc(100% - 2em);}

/*creacion partidas*/
.etiquetaPartida svg g path:first-of-type{fill: var(--color-principal);stroke: var(--color-principal);}
label.error, span.error{color: red !important}
input.error{border: 1px solid red}
button.disabled{opacity: 0.4;pointer-events: none}
.error .ng-select-container{border: 1px solid red !important}
/* inputs */
.input{font-size: var(--tamanyo-letra);padding: 0.6em 1em;border: solid 1px var(--color-principal);border-radius: 7px;font-family: 'IBM PLEX SANS';}
/* buttons */
.btn{font-family: 'IBM Plex Sans';display:inline-flex;justify-content: center;border-radius: var(--border-radius-small);text-align: center;padding: 10px 3em;background: var(--color-principal);border:none;color:#d6e2e4;cursor: pointer;outline: none;border:2px solid var(--color-principal);font-size: var(--tamanyo-letra);}
.btn.buttonSizeSmall{padding:6px 3em;}
.btn.buttonSizeMini{padding:3px 3em;}
.btn.buttonSizeBig{font-size: 24px;padding: .5em 1.8em;}
.btn.btn_secondary{background: transparent;color:var(--color-principal)}
.btn.btn_secondary:hover{background: var(--color-suave);}
.btn:hover{box-shadow: var(--shadow-small);}
.btn:active,.buttonsinimg:active{box-shadow: inset var(--shadow-small);}
.btn:disabled:hover,.buttonsinimg:disabled:hover,.btn:disabled:active,.buttonsinimg:disabled:active,.btn:disabled,.buttonsinimg:disabled{box-shadow: none;opacity: var(--opacity-desactivado);cursor: pointer;}
/* inputFile */
.fileInputParent{position: relative;}
input[type='file'].fileInput{position: absolute;top:0;left:0;width: 100%;height: 100%;box-sizing: border-box;z-index: 20;opacity: 0;cursor: pointer;}
/* Notificaciones */
.notificacionesContainer{position: fixed;bottom: 0;right: 0;z-index: 9999;pointer-events: none;}
.notificacion{font-family: 'IBM Plex Sans';width: 576px;display: flex;background: white;box-shadow: 6px 6px 12px rgba(0,0,0,.16);border-radius: 12px;margin: 2em 2em 2em 0em;min-height: calc(96px - 1em);padding:1em;opacity: 1;transition: opacity .5s, transform .2s;transform: translateX(150%);}
.notificacion:before{content: '';width: 8px;border-radius: 8px;margin-right: 1.5em;}
.notificacion.notificacionError:before{background: var(--color-error)}
.notificacion.notificacionWarning:before{background: var(--color-warning)}
.notificacion.notificacionInfo:before{background: #808080}
.notificacion.notificacionFeedback:before{background: var(--color-principal);}
.notificacion .notificacion_cerrar{display: block;margin-top: 28px;margin-left: 1em;pointer-events: auto;}
.notificacion .notificacion_cerrar:after{font-family: 'Material Icons';font-size: 24px;content: 'close';color:#B8B8B8;cursor: pointer;}
.notificacion .notificacion_cerrar:hover:after{color:#000;}
.notificacion .notificacion_icon{width: 24px;margin-right: 1em;padding-top: 28px;display: block;}
.notificacion.notificacionInfo .notificacion_icon{width: 0px;margin-right: 0;}
.notificacion .notificacion_icon:after{font-family: 'Material Icons';font-size: 24px;}
.notificacionError .notificacion_icon:after{content: 'error_outline';color:var(--color-error)}
.notificacionWarning .notificacion_icon:after{content: 'warning';color:var(--color-warning)}
.notificacionFeedback .notificacion_icon:after{content: 'check_box';color:var(--color-principal);}
.notificacion.notificacion_containText .notificacion_icon{padding-top: 11px;}
.notificacion.notificacion_containText .notificacion_body h4{margin-block-start: 0.5em;margin-block-end: 0.5em;}
.notificacion.notificacion_containText .notificacion_body p{margin-block-start: 0.5em;margin-block-end: 0.5em;}
.notificacion_body{flex:1;}
.notificacion_animationOpen{transform: translateX(0);}
.notificacion_animationRemove{opacity: 0;}
/* Radio check */
.containerRadioCheck{height: 24px;width: 24px;position: relative;}
.radioCheckStyle{opacity: 0;position: absolute;top: 0;left: 0;right: 0;bottom: 0;display: block;appearance: none;margin: 0;}
.radioCheckStyle+.auxRadioCheckStyle{height: calc(100% - 4px);width: calc(100% - 4px);border: 2px solid;border-radius: 200px;display: inline-block;pointer-events: none;}
.radioCheckStyle+.auxRadioCheckStyle::after{content:'';position: absolute;width: calc(70% - 4px);height: calc(70% - 4px);top: 50%;left:50%;transform: translate(-50%,-50%);border-radius: 200px;}
.radioCheckStyle:checked+.auxRadioCheckStyle::after{background: var(--color-principal);}
/* Ng select */
.selector{display: inline-block;}
.ng-select{min-width: 240px;border-radius: var(--border-radius-small);background: var(--color-blanco);font-family: 'IBM Plex Sans';}
.ng-select.secundaryTheme{background:var(--color-suave);}
.ng-select * {line-height: 24px;}
.ng-select .ng-select-container{border-radius: var(--border-radius-small);border:1px solid var(--color-gris2);}
/* .ng-select .ng-select-container:hover{box-shadow: var(--shadow-small);} */
.ng-select.secundaryTheme .ng-select-container{border-color:var(--color-suave);}
.ng-select.ng-select-opened.secundaryTheme .ng-select-container{border-color:var(--color-principal);}
.ng-select.fix_height .ng-select-container,.ng-select.fix_height .ng-select-container .ng-value-container{height: 100%;max-height: 100%;}
.ng-select .combobox{padding: 1.5em 0.5em;}
.ng-select, .ng-select div, .ng-select input, .ng-select span{box-sizing: border-box;}
.ng-select .ng-arrow-wrapper{width: 25px;padding-right: 5px;cursor: pointer;position: relative;text-align: center;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.ng-select .ng-arrow-wrapper .ng-arrow{pointer-events: none;display: inline-block;height: 0;width: 0;position: relative;width: 16px !important;height: 16px !important;position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);}
.ng-select .ng-arrow-wrapper .ng-arrow::after{content: 'arrow_drop_down';font-family: 'Material Icons';color:var(--color-principal);position: relative;bottom: 4px;font-size: 45px;right: 24px;}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow::after{content: 'arrow_drop_up';}
.ng-select .ng-clear-wrapper{cursor: pointer;position: relative;width: 17px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;color:var(--color-gris1);}
.ng-select .ng-clear-wrapper:hover .ng-clear {color: var(--color-error);}
.ng-select .ng-clear-wrapper .ng-clear {display: inline-block;font-size: 18px;line-height: 1;pointer-events: none;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);}
.ng-select .ng-value-container{min-height: 46px;padding:.7em;max-height: 46px;position: relative;}
.ng-select .ng-select-container .ng-value-container .ng-input{left: 0;position: absolute;width: 100%;height: 100%;top: 0;}
.ng-select .ng-select-container .ng-value-container .ng-input>input{background: none transparent;border: 0;box-sizing: content-box;width: calc(100% - .7em) !important;border-radius: 0;padding: .7em !important;position: relative;padding-right: 0.2em !important;}
.ng-select.fix_height .ng-select-container .ng-value-container .ng-input>input{height: calc(100% - 1.4em);}
.ng-select .ng-placeholder{max-width: calc(100% - .7em);white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
.ng-select.ng-select-multiple{max-width: 240px;}
.ng-select.ng-select-multiple .ng-value-container{max-width: 82%;}
.ng-select.ng-select-multiple .ng-value{max-width:100%;overflow: hidden;text-overflow: ellipsis;font-size: 0.9em;margin-bottom: 5px;background-color: var(--color-principal-trasparente);border-radius: var(--border-radius-mini);margin-right: 5px;}
.ng-select.ng-select-multiple .ng-value .ng-value-icon {background-color: var(--color-principal-trasparente);display: inline-block;padding: 1px 5px;}
.ng-select.ng-select-multiple .ng-value .ng-value-icon:hover{background-color: var(--color-principal-trasparente-hover);border-top-left-radius: 4px;border-bottom-left-radius: 4px;}
.ng-select.ng-select-multiple .ng-value .ng-value-label {display: inline-block;padding: 1px 5px;}
.ng-select.ng-select-multiple .ng-value.ng-simpleText{background-color: transparent;max-width: calc(100% - 5px);}
.ng-select.ng-select-filtered .ng-value {opacity: 0.1;}
.ng-select .ng-has-value .ng-placeholder{display: none;}
.ng-select.ng-select-opened .ng-select-container{border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;}
.ng-dropdown-panel.ng-select-top .ng-dropdown-header{border-top: 1px solid var(--color-gris2);border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;}
.ng-dropdown-panel.ng-select-top .ng-dropdown-header+.ng-dropdown-panel-items{border-top: 0px solid var(--color-gris2);border-radius: 0 0 0 0;}
.ng-select.ng-select-opened.ng-select-top > .ng-select-container{border-bottom-left-radius: var(--border-radius-small);border-bottom-right-radius: var(--border-radius-small);border-top-right-radius: 0;border-top-left-radius: 0;}
.ng-select.ng-select-opened .ng-select-container:hover{box-shadow: none;}
.ng-dropdown-panel-items{text-align:left;padding: 8px 8px .5em .5em;border:1px solid var(--color-gris2);border-top: none;border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);background: var(--color-blanco);}
.ng-select.secundaryTheme .ng-dropdown-panel-items{background:var(--color-suave);border-color:var(--color-principal);}
.ng-select.secundaryTheme.documentos .ng-dropdown-panel-items{background:white;border-color:var(--color-principal);}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items{border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;border-bottom: 0px;border-top: 1px solid var(--color-gris2);}
.ng-dropdown-header{border-left: 1px solid var(--color-gris2);border-right: 1px solid var(--color-gris2);background: var(--color-blanco);}
.ng-dropdown-panel{left:0;}
.ng-option-label{font-weight: normal;}
.ng-option.ng-option-selected {color: var(--color-principal);}
.ng-option.ng-option-selected .ng-option-label{font-weight: 500;}
.ng-option{max-height: 48px;padding: 0.6em;border-radius: var(--border-radius-small);}
.ng-option:hover{background: var(--color-fondo-encima)}
.ng-option.disabled:hover{background: transparent;}
.ng-select .selector_textTop_icon{vertical-align: text-top;}
.ng-select .weekRange{font-size: 14px;color:var(--color-gris1);}
.selector.styleSelectSecundario .ng-select{background-color: var(--color-fondo-encima);}
.selector.styleSelectSecundario .ng-select .ng-dropdown-panel .ng-dropdown-panel-items{background-color: var(--color-fondo-encima);}
.selector.estadoRealizado .ng-select{background-color: var(--color-gris1);color:var(--color-blanco);}
.selector.estadoDesarrollo .ng-select{background-color: var(--color-gris2);color:var(--color-blanco);}
.selector.estadoPendiente .ng-select{background-color: var(--color-gris3);}
.selector.estadoRealizado .ng-select.ng-select-opened,.selector.estadoRecurrente .ng-select.ng-select-opened,.selector.estadoDesarrollo .ng-select.ng-select-opened,.selector.estadoPendiente .ng-select.ng-select-opened{background-color:var(--color-blanco);color:initial;}
.selector.estadoRealizado .ng-select .ng-clear-wrapper,.selector.estadoRealizado .ng-select .ng-arrow-wrapper .ng-arrow::after,.selector.estadoDesarrollo .ng-select .ng-clear-wrapper,.selector.estadoDesarrollo .ng-select .ng-arrow-wrapper .ng-arrow::after{color: white;}
.selector.estadoRealizado .ng-select.ng-select-opened .ng-clear-wrapper,.selector.estadoRealizado .ng-select.ng-select-opened .ng-arrow-wrapper .ng-arrow::after,.selector.estadoRecurrente .ng-select.ng-select-opened .ng-clear-wrapper,.selector.estadoRecurrente .ng-select.ng-select-opened .ng-arrow-wrapper .ng-arrow::after,.selector.estadoDesarrollo .ng-select.ng-select-opened .ng-clear-wrapper,.selector.estadoDesarrollo .ng-select.ng-select-opened .ng-arrow-wrapper .ng-arrow::after{color: var(--color-gris1);}
.ng-dropdown-panel .SelectorSelectAllBoton button{padding: 0px 6px;width: calc(100% - 2em);margin: 0.5em 1em 0px;max-width: calc(100% - 2em);white-space: nowrap;text-overflow: ellipsis;overflow: hidden;box-shadow: none;background: none;border: none;color: var(--color-principal);}
body>.ng-dropdown-panel{z-index: 2050;}
/* Popup */
.cdk-overlay-backdrop{ background:var(--color-fondo-popUp);}
.cdk-overlay-pane{min-width: 350px;min-height: 230px;}
.cdk-overlay-pane mat-dialog-container{position: relative;border-radius: var(--border-radius-big);padding:0px;max-width: 90vw;}
.closePopUpIcon{position: absolute;top: 24px;right: 24px;color: var(--color-gris2);font-size: 20px;cursor: pointer;z-index: 150;}
.closePopUpIcon:hover{color:var(--color-gris0);}
.closePopUpIcon::after{content: 'close';font-family: 'Material Icons';font-weight: bold;}
.popupContent{display: flex;flex-direction: column;min-height: 186px;}
.popupContent > * {text-align: center;}
.popupContent .popupBody{flex: 1;margin-bottom: 54px;margin-top: 22px;}
.popupContent.smallBottomMargin .popupBody{margin-bottom: 4px;}
.popupContent .popupBody.popupCenterHeightContainer{display: flex;align-items: center;}
.popupContent .popupBody.popupBottomMarginSplit{margin-bottom: 22px;}
.popupContent.confirmarPopups .popupBody p{margin-bottom: 0px;margin-top: 0px;line-height: 32px;font-size: 20px;}
.popupContent .popupTitle{margin-top: 0;}
.globalPopupContainer{padding:24px;padding-top: 20px;}
.templatePopup .popupHead{padding: 20px 64px 0;border-bottom: 1px solid var(--color-gris2);text-align: left;}
.templatePopup .popupHead .material-icons{margin-right: 1em;position: relative;top: 2px;}
.templatePopup .popupBody{padding: 20px 64px;text-align: left;max-height: 50vh;overflow: auto;}
.templatePopup .popupButtons{text-align: right;}
.templatePopup .popupButtons{padding: 20px 32px;background: var(--color-gris4);}
.cdk-overlay-pane.fullSizePopup{max-width: 100vw !important;min-width: 100vw;min-height: 100vh;max-height: 100vh !important;}
.cdk-overlay-pane.fullSizePopup mat-dialog-container{border-radius: 0;max-width: 100vw;background: var(--color-gris4);}
.cdk-overlay-pane.fullSizePopup .returnVolver {color: var(--color-principal);}
.cdk-overlay-pane.fullSizePopup .returnVolver:before {content: 'arrow_back_ios';font-family: 'Material Icons';vertical-align: bottom;font-size: 13px;font-weight: bold;}
/* Icono Carga */
.iconoCargar{animation: cargarAnimacion 1s ease-in-out infinite;display: inline-block;position: relative;transform: rotate(180deg);}
@keyframes cargarAnimacion{0% {transform: rotate(-180deg);}100% {transform: rotate(180deg);}}
.cdk-overlay-pane.loadingPopup{min-width: 140px;min-height: 140px;text-align: center;}
.cdk-overlay-pane.loadingPopup .mat-dialog-container{background: transparent;box-shadow: none;}
.cdk-overlay-pane{max-width: 90vw !important;}
.cdk-overlay-container{z-index: 2000;}
body.loaderActive > .cdk-overlay-container{z-index: 5000000;}

/* Media Queries mobile size to desktop size
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Larger than mobile */
@media (min-width: 400px) {}
/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {}
/* Larger than tablet */
@media (min-width: 750px) {}
/* Larger than desktop */
@media (min-width: 1000px) {}
/* Larger than Desktop HD */
@media (min-width: 1200px) {}

/* Media Queries desktop size to mobile size
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Larger than mobile */
@media (max-width: 400px) {}
/* Larger than phablet (also point when grid becomes active) */
@media (max-width: 550px) {}
/* Larger than tablet */
/* MOBILE BREAKPOINT */
@media (max-width: 830px) {}
/* Larger than desktop */
@media (max-width: 1000px) {}
/* Larger than Desktop HD */
@media (max-width: 1200px) {}

